<template>
  <main class="container">
    <div class="svg-image">
      <img src="../assets/thumbnails/404-2.svg" />
    </div>

    <div class="message-box">
      <div class="content-box">
        <h1>Ooops...</h1>
        <p style="text-align: left">
          Sorry, the page you are looking for doesn't exist.
        </p>
        <div class="buttons-con">
          <div class="action-link-wrap">
            <a @click="$router.go(-1)" class="link-button link-back-button"
              >Go Back</a
            >
            <a class="link-button" @click="goHome()">Home Page</a>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
export default {
  methods: {
    goHome() {
      this.$router.push({ name: "Home" });
    },
  },
  async mounted() {
    await this.$store.dispatch("unitModule/unit", null);
  },
};
</script>

<style scoped>
.container {
  width: 100%;
  height: 100%;
  min-height: 644px px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  /* gap: 64px; */
}
.svg-image {
  width: 100%;
  height: 100%;
}
.svg-image > img {
  width: 100%;
  height: 100%;
}
.message-box {
  display: flex;
  flex-flow: column;
  height: 100%;
  width: 100%;
  color: #fff;
  font-weight: 300;
  letter-spacing: 2px;
  align-items: center;
  justify-content: center;
  gap: 20px;
}
.message-box h1 {
  font-size: 60px;
  line-height: 46px;
  /* margin-bottom: 40px; */
}

.content-box {
  display: flex;
  flex-flow: column;
  gap: 20px;
}
.buttons-con .action-link-wrap a {
  background: var(--highlight);
  padding: 8px 25px;
  border-radius: 4px;
  color: #fff;
  font-weight: bold;
  font-size: 14px;
  transition: all 0.3s linear;
  cursor: pointer;
  text-decoration: none;
  margin-right: 10px;
}
.buttons-con .action-link-wrap a:hover {
  background: var(--white1);
  color: var(--primary);
}
.action-link-wrap {
  display: flex;

  /* flex-flow: column; */
  gap: 20px;
}

.mention {
  position: absolute;
  bottom: 0;
  right: 0;
  font-size: 6px;
  letter-spacing: 0;
  text-decoration: none;
  color: #fff;
  cursor: none;
}

@media only screen and (max-width: 699px) {
  .container {
    flex-flow: column;
    align-items: center;
    justify-content: center;
    overflow: scroll;
    gap: 0px;
  }
  .svg-image {
    width: 100%;
    height: 400px;
  }
  .message-box {
    text-align: center;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 200px;
  }
  h1 {
    display: none;
  }
  p {
    display: none;
  }
  .action-link-wrap {
    display: flex;
    width: 200px;
    flex-flow: column;
    gap: 20px;
  }
}
</style>
